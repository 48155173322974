import { render, staticRenderFns } from "./stage.vue?vue&type=template&id=0b220ee4&scoped=true"
import script from "./stage.vue?vue&type=script&lang=js"
export * from "./stage.vue?vue&type=script&lang=js"
import style0 from "./stage.vue?vue&type=style&index=0&id=0b220ee4&prod&scoped=true&lang=less"
import style1 from "./stage.vue?vue&type=style&index=1&id=0b220ee4&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b220ee4",
  null
  
)

export default component.exports