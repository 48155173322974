<template>
  <div class="all-box">
    <img class="bg-top" src="@/assets/img/performance/bg-4.png" />
    <img class="bg-bottom" src="@/assets/img/performance/bg-5.png" />
    <div class="foun-top">
      <Breadcrumb class="bread breadcrumb-1400" :list="list"></Breadcrumb>
    </div>
    <div class="foun-box">
      <leftMenu :id="this.listId"></leftMenu>
      <div class="foun">
        <div class="content-list">
          <div class="content-top">
            <div class="top-left">
              <div class="left-bg"></div>
              <div class="left-title">舞美</div>
            </div>
            <div class="top-right">
              <el-input
                v-model="input1"
                @clear="handleClear"
                clearable
                class="input"
              >
                <el-button
                  slot="append"
                  @click="search"
                  icon="el-icon-search"
                ></el-button>
              </el-input>
            </div>
          </div>
          <div class="content-mid">
            <!-- <screened></screened> -->
            <div class="all">
              共有'<span style="color: #f9680d">{{ number }}</span
              >'位
            </div>
          </div>
          <!-- <div class="line"></div> -->
          <div class="personBox">
            <div
              class="person"
              @click="toDetail(item.id)"
              v-for="item in personList"
              :key="item.id"
            >
              <img class="personLeft" :src="item.image" :onerror="defaultImg" />
              <div class="personRight">
                <div class="name">{{ item.name }}</div>
                <div class="detail">
                  <div class="detailLeft">职业</div>
                  <div class="detailRight">{{ item.typeName }}</div>
                </div>
                <div class="detail">
                  <div class="detailLeft">民族</div>
                  <div class="detailRight">{{ item.nation }}</div>
                </div>
                <div class="detail">
                  <div class="detailLeft">籍贯</div>
                  <div class="detailRight">{{ item.job }}</div>
                </div>
                <div class="detail">
                  <div class="detailLeft">毕业院校</div>
                  <div class="detailRight">{{ item.school }}</div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="bottom">
                        <el-pagination v-if="this.personList.length>0" background layout="prev, pager, next" @current-change="handlePageChange" :page-sizes="this.pager.pageSizes" :current-page="this.pager.pageNo" :total="this.number">
                        </el-pagination>
                    </div> -->
        </div>
      </div>
    </div>
    <PageFooter />
  </div>
</template>
<script>
import leftMenu from "./LeftMenu";
import Breadcrumb from "../../../components/pc/Breadcrumb.vue";
import screened from "./screened.vue";
import { getEntertainer, clickEntertainer } from "@/service/PeopleEntertainers";
export default {
  components: {
    leftMenu,
    Breadcrumb,
    screened,
  },
  data() {
    return {
      defaultImg:
        'this.src="' + require("../../../assets/imgs/defaultDiagram.jpg") + '"',
      listId: 6,
      personList: [],
      list: ["人艺人", "舞美"],
      input1: "",
      number: 0,
      pager: {
        pageNo: 1,
        pageSize: 9999,
        totalPage: 0,
      },
      form: {
        typeId: 28,
        firstLetter: "",
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    handleClear() {
      this.form.name = "";
      this.getList();
    },
    handlePageChange(val) {
      this.pager.pageNo = val;
      this.getList();
    },
    search() {
      this.form.name = this.input1;
      this.getList();
    },
    async getList() {
      const params = { ...this.form };
      const res = await getEntertainer({
        ...this.pager,
        condition: params,
      });
      this.personList = res.data.data;
      this.pager = {
        pageNo: res.data.pageNo,
        totalPage: res.data.totalPage,
        pageSize: res.data.pageSize,
      };
      this.number = res.data.totalCount;
      // 根据 firstLetter 字段按字母顺序排序
      // this.personList.sort((a, b) => {
      //   if (a.firstLetter < b.firstLetter) {
      //     return -1;
      //   }
      //   if (a.firstLetter > b.firstLetter) {
      //     return 1;
      //   }

      //   // 如果 firstLetter 相同，则根据姓氏排序
      //   const lastNameA = a.name.charAt(0);
      //   const lastNameB = b.name.charAt(0);

      //   if (lastNameA < lastNameB) {
      //     return -1;
      //   }
      //   if (lastNameA > lastNameB) {
      //     return 1;
      //   }

      //   return 0;
      // });
    },
    toDetail(id) {
      clickEntertainer(id);
      const { href } = this.$router.resolve({
        path: "/PeopleEntertainers/detail",
        query: {
          id: id,
          page: "6",
        },
      });
      window.open(href, "_blank");
    },
  },
};
</script>
<style scoped lang="less">
.all-box {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #f8f8fa;
  margin-top: -1.0417vw;
  z-index: 2;
  .bg-top {
    position: absolute;
    width: 58.6979vw;
    height: 23.4375vw;
    z-index: -1;
  }

  .bg-bottom {
    position: absolute;
    right: 0;
    bottom: 31.25vw;
    width: 11.1458vw;
    height: 55.2083vw;
    z-index: -1;
  }
  .foun-top {
    width: 11.9792vw;
    height: 0.7292vw;
    margin-top: 1.0417vw;
    margin-left: 13.5417vw;
    .bread {
      padding-top: 1.0417vw;
    }
  }

  .foun-box {
    height: auto;
    min-height: 53.75vw; /* 设置最小高度为 1032px */
    margin: 2.6042vw 13.5417vw 2.6042vw 13.5417vw;
    display: flex;
    justify-content: center;

    .foun {
      width: 56.25vw;
      // height: 1860px;
      margin-left: 2.0833vw;
      z-index: 5;
      background: #ffffff;

      .content-list {
        width: 51.0417vw;
        // height: 1760px;
        margin: 2.6042vw;

        .content-top {
          display: flex;
          justify-content: space-between;

          .top-left {
            display: flex;

            .left-bg {
              background-image: url("../../../assets/imgs/introduction.png");
              width: 1.3542vw;
              height: 1.3542vw;
              background-size: contain;
              background-repeat: no-repeat;
            }

            .left-title {
              height: 1.3542vw;
              font-size: 1.3542vw;
              font-family: SourceHanSansCN-Medium, SourceHanSansCN;
              font-weight: 500;
              color: #212122;
              line-height: 1.3542vw;
              margin-left: 1.3542vw;
            }
          }

          .top-right {
            width: 14.5833vw;
            height: 1.875vw;
          }
        }

        .content-mid {
          height: 2.6042vw;
          width: 100%;
          margin-top: 0.8333vw;

          .all {
            display: flex;
            height: 1.0417vw;
            line-height: 1.0417vw;
            font-size: 0.8333vw;
          }
        }

        .line {
          width: 100%;
          height: 0.0521vw;
          margin-top: 2.6042vw;
          background: #f4f4f4;
        }

        .personBox {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;

          /* 超出换行 */
          .person {
            width: 24.4792vw;
            height: 14.5833vw;
            display: flex;
            margin-top: 1.3021vw;
            cursor: pointer;

            .personLeft {
              width: 10.4167vw;
              height: 13.5417vw;
              object-fit: cover;
            }

            .personRight {
              width: 15.1042vw;
              height: 13.5417vw;
              margin-left: 1.0417vw;

              .name {
                width: 9.8958vw;
                height: 1.25vw;
                font-size: 1.25vw;
                font-family: SourceHanSansCN-Heavy, SourceHanSansCN;
                font-weight: 800;
                color: #212122;
                line-height: 1.875vw;
                text-align: left;
              }

              .detail {
                display: flex;
                margin-top: 1.4583vw;

                .detailLeft {
                  width: 4.375vw;
                  height: 1.5625vw;
                  background: #bf805f;
                  border-radius: 0.0521vw;
                  font-weight: 400;
                  font-size: 0.8333vw;
                  text-align: center;
                  line-height: 1.5625vw;
                  color: #ffffff;
                }

                .detailRight {
                  width: 9.375vw;
                  height: 1.5625vw;
                  font-size: 0.8333vw;
                  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
                  font-weight: 500;
                  color: #212122;
                  line-height: 1.5625vw;
                  margin-left: 0.4167vw;
                }
              }
            }
          }
        }

        .bottom {
          margin-top: 4.1667vw;
          width: 100%;
          height: 1.6667vw;
          text-align: center;
        }
      }
    }
  }
}
</style>
<style scoped>
::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #f9680d;
}
</style>
